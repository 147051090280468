import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet'
import MetaHeader from "../components/MetaHeader"

export default function IndexPage() {
    return (
        <PageWrapper>
            <BodyStyle />
            <MetaHeader />
            <DownloadsWrapper>
                <DownloadItem href='https://apps.apple.com/app/blockit-block-distractions/id1492879257'>
                    <DownloadHeader>
                        <BlockitHeading>#blockit</BlockitHeading>
                        <p>Download for iPhone or iPad</p>
                    </DownloadHeader>
                    <StaticImage src='../images/ipad.and.iphone.svg' alt='iPad and iPhone' placeholder='tracedSVG' />
                </DownloadItem>
                <DownloadItem href='https://apps.apple.com/app/blockit-for-safari/id1492879787?mt=12'>
                    <DownloadHeader>
                        <BlockitHeading>#blockit</BlockitHeading>
                        <p>Download for Mac</p>
                    </DownloadHeader>
                    <StaticImage src='../images/laptopcomputer.svg' alt='Macbook' placeholder='tracedSVG' />
                </DownloadItem>
            </DownloadsWrapper>

            <p><a href='https://blockit.helpsite.com/contact'>Contact</a> | <a href='https://blockit.helpsite.com'>Support</a></p>
        </PageWrapper>
    )
}

const BodyStyle = createGlobalStyle`
    body {


        font-family: "Helvetica Neue",-apple-system,BlinkMacSystemFont,"Helvetica",sans-serif;
        font-size: 16px;
        margin: 0;
    }

    :root {
        background: linear-gradient(#3AD7FF, #03A7FF) no-repeat center center fixed;
        background-size: cover;

        --shadow-color: 201deg 99% 26%;
        --shadow-elevation-low:
            0px 0.7px 0.8px hsl(var(--shadow-color) / 0.4),
            0px 1.2px 1.4px -1.2px hsl(var(--shadow-color) / 0.4),
            0px 2.9px 3.3px -2.4px hsl(var(--shadow-color) / 0.41);
        --shadow-elevation-medium:
            0px 0.7px 0.8px hsl(var(--shadow-color) / 0.33),
            0px 1.9px 2.2px -0.6px hsl(var(--shadow-color) / 0.34),
            0px 3.7px 4.2px -1.2px hsl(var(--shadow-color) / 0.34),
            0.1px 7.4px 8.4px -1.8px hsl(var(--shadow-color) / 0.34),
            0.1px 14.4px 16.3px -2.4px hsl(var(--shadow-color) / 0.34);
        --shadow-elevation-high:
            0px 0.7px 0.8px hsl(var(--shadow-color) / 0.31),
            0px 3.7px 4.2px -0.3px hsl(var(--shadow-color) / 0.31),
            0.1px 6.6px 7.5px -0.5px hsl(var(--shadow-color) / 0.31),
            0.1px 10px 11.3px -0.8px hsl(var(--shadow-color) / 0.31),
            0.1px 14.7px 16.6px -1.1px hsl(var(--shadow-color) / 0.31),
            0.2px 21.1px 23.9px -1.4px hsl(var(--shadow-color) / 0.32),
            0.3px 30px 34px -1.6px hsl(var(--shadow-color) / 0.32),
            0.4px 41.9px 47.5px -1.9px hsl(var(--shadow-color) / 0.32),
            0.5px 57.6px 65.2px -2.2px hsl(var(--shadow-color) / 0.32),
            0.7px 77.5px 87.8px -2.4px hsl(var(--shadow-color) / 0.32);
    }
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    min-height: 100vh;

    padding: 0 2rem;

    color: white;
    text-align: center;

    @media screen and (max-width: 800px) {
        padding: 2rem;
    }

    >p {
        position: fixed;
        bottom: 1.5rem;
        font-size: 1rem;
        margin: 0;

        a {
            text-decoration: none;
            color: white;

            &:hover {
                text-decoration: underline;
            }
        }

        @media screen and (max-height: 600px) {
            position: static;
        }

        @media screen and (max-width: 800px) {
            margin: 2rem 0 0;
            position: static;
        }
    }
`;

const BlockitHeading = styled.p`
    font-size: 4rem;
    font-weight: 500;
    background-color: #03A7FF;
    background-image: linear-gradient(#3AD7FF,#03A7FF);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
`;

const DownloadsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 2rem;
    color: black;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`;

const DownloadItem = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    max-width: 400px;

    background-color: white;
    text-decoration: none;
    color: black;
    padding: 20px;
    border-radius: 15px;

    box-shadow: var(--shadow-elevation-medium);

    transition: all 0.3s ease;

    img {
        width: auto !important;
        max-width: 100% !important;
        height: 150px !important;
        margin: 2rem auto !important;
    }

    &:hover {
        background-color: whitesmoke;
        box-shadow: var(--shadow-elevation-low);
        transform: scale(0.99);
    }

    @media screen and (max-width: 800px) {
        min-width: 300px;
    }
`;

const DownloadHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p {
        margin-bottom: 0;
        color: #343434;
    }
`;

