import React from 'react';
import { Helmet } from 'react-helmet';

export default function ArticleHeader() {
	const fullURL = "https://blockit.app";
	const imageURL = fullURL + "/meta-photo.png";
	const title = "Download #blockit"
	const description = "Stop attention theft - Download #blockit for iOS, iPadOS, and macOS"

	return (
		<Helmet 
		htmlAttributes={{
			lang: 'en',
		}}
		>
			

			<title>{title}</title>
			<meta name="description" content={description} />
			<link rel="shortcut icon" type="image/png" href="/favicon.png"/>
			<link rel="icon" type="image/png" href="/favicon.png"/>

			{/* Open Graph tags */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={fullURL} />
			<meta property="og:site_name" content={title} />
			<meta property="og:locale" content="en_AU" />
			<meta property="og:site_name" content={title} />
			<meta property="og:image" content={imageURL} />

			{/* Open Graph tags: Article Specific */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			{/* Sharing: Twitter */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:url" content={fullURL} />
			<meta name="twitter:site" content="#blockit" />
			<meta name="twitter:image" content={imageURL} />

			{/* Safari Color */}
			<meta name='theme-color' content='#3AD7FF' />
		</Helmet>
	);
}
